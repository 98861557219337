import React from "react";
import Layout from "../components/Layout";
import { PageProps, graphql, Link } from "gatsby";
import Card from "../components/Card";

interface Edge {
  node: {
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
    };
    headings: {
      value: string;
    };
    excerpt: string;
  };
}

interface Query {
  allMarkdownRemark: {
    edges: Edge[];
  };
}

export default function Index({ data }: PageProps & { data: Query }) {
  return (
    <Layout>
      <div className="flex flex-col gap-4 py-4 px-8 bg-gray-50 shadow rounded flex-1 prose max-w-full">
        <div className="mx-auto">
          <h1>So, this is a personal blog.</h1>
          <p>
            It should be fairly obvious, I&rsquo;m not experienced in web
            development. Yes, I wrote the blog markup too. Feel free to look
            around, remember I'm only human, and{" "}
            <a href="https://twitter.com/rhg135">here is my twitter</a>
          </p>
        </div>
        <h2 className="text-right">Posts</h2>
        <div className="grid grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-4">
          {data.allMarkdownRemark.edges.map((edge) => (
            <Link className="none" to={edge.node.fields.slug}>
              <Card>
                <h3>{edge.node.frontmatter.title}</h3>
                <p className="overflow-x-hidden">{edge.node.excerpt}</p>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  fragment ExcerptFragment on MarkdownRemark {
    excerpt(pruneLength: 200, truncate: true)
  }
  {
    allMarkdownRemark(
      sort: { fields: frontmatter___modified, order: DESC }
      filter: { frontmatter: { title: { ne: "" }, public: { eq: true } } }
    ) {
      edges {
        node {
          ...ExcerptFragment
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
